@use "src/styles/_utils.scss";

/* MenuButton */
.buttonLines {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
}

.buttonLine {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  $duration: 0.3s;
  transition: transform $duration ease, opacity $duration ease;

  $normalTLY: 6px;

  &:nth-of-type(1) {
    transform: translateY(-$normalTLY);
  }

  &:nth-of-type(2) {
    transform: translateY(0);
    opacity: 1;
  }

  &:nth-of-type(3) {
    transform: translateY($normalTLY);
  }
}

@include utils.mqmin(utils.$sm) {
  .button:hover,
  .button.button.isOpen:hover {
    .buttonLine {
      $hoverTLY: 3px;

      &:nth-of-type(1) {
        transform: translateY(-$hoverTLY);
      }

      &:nth-of-type(2) {
        transform: translateY(0);
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY($hoverTLY);
      }
    }
  }
}

.button.isOpen {
  .buttonLine {
    $openRotateZ: 40deg;
    $openScaleX: 1;

    &:nth-of-type(1) {
      transform: translateY(0) rotateZ(-$openRotateZ) scaleX($openScaleX);
    }

    &:nth-of-type(2) {
      transform: translateY(0) scaleX(0);
    }

    &:nth-of-type(3) {
      transform: translateY(0) rotateZ($openRotateZ) scaleX($openScaleX);
    }
  }
}

/* MenuList */
.navWrapper {
  right: -320px;
  width: calc(100% + 320px);
  max-width: 448 + 320px;

  @include utils.mqmin(utils.$sm) {
    width: calc(33.333333% + 320px);
  }
}

.navList {
  margin-right: auto;
  width: calc(100% - 320px);
}
