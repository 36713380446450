.pin {
  position: absolute;
  z-index: 1;
  width: 8%;
  height: 8%;
  object-position: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.awaji {
  top: 71%;
  left: 29%;
}

.tokyo {
  top: 64%;
  left: 53%;
}
